import {
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_GRID,
  LAYOUT_PG_GRID_INTERMEDIATE,
  LAYOUT_PG_GRID_LARGE,
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_SIDE_BY_SIDE_RIGHT,
  LAYOUT_PG_TEXT_ON_IMAGE_SMALL,
  LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_PG_TEXT_ON_IMAGE_LARGE,
  LAYOUT_PG_ONE_COLUMN,
  LAYOUT_SLIDER,
  LAYOUT_LIST,
  LAYOUT_LIST_MEDIUM,
  LAYOUT_LIST_LARGE,
  PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS,
  PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS,
  PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS,
  isLayoutPGSideBySide,
} from '@wix/communities-blog-client-common';
import {
  HOVERING_BEHAVIOUR,
  OPTION_GALLERY_SIZE_TYPE,
  OVERLAY_ANIMATION,
  IMAGE_HOVER_ANIMATION,
  IMAGE_LOADING_MODE,
  CUBE_TYPE,
  TEXT_PLACEMENT,
  CALCULATE_TEXT_BOX_HEIGHT,
  TEXT_BOX_WIDTH_CALCULATION_OPTIONS,
} from '../../constants/pro-gallery-options';

export const getLayoutImageRatio = (layout, options, cropType) => {
  if (options.hideCoverImage && options.layoutAutoHeightEnabled) {
    return 99;
  }
  if (`${cropType}` !== PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS.MAX) {
    return options.layoutImageRatio;
  }

  if (isLayoutPGSideBySide(layout)) {
    return 4 / 3;
  }

  return 16 / 9;
};

export const createOptions = (layout, options, itemBorder) => {
  const galleryWidth = options.containerWidth - itemBorder.width;
  const imageMargin = -(galleryWidth > 0
    ? itemBorder.width
    : itemBorder.width - 1 + galleryWidth);

  const commonGalleryOptions = {
    gallerySizeType: OPTION_GALLERY_SIZE_TYPE.PX,
    gallerySizePx: options.layoutPostSize,
    imageMargin:
      options.layoutSpacing === 0 ? imageMargin : options.layoutSpacing,
    enableInfiniteScroll: true,
    allowTitle: true,
    allowContextMenu: true,
    overlayAnimation: OVERLAY_ANIMATION.FADE_IN,
    imageHoverAnimation: IMAGE_HOVER_ANIMATION.NO_EFFECT,
    imageLoadingMode: IMAGE_LOADING_MODE.BLUR,
    imageInfoType: 'ATTACHED_BACKGROUND',
    textsHorizontalPadding: -30,
    itemBorderWidth: itemBorder.width,
    itemBorderColor: itemBorder.color,
    isRTL: options.isRTL,
    videoPlay: options.videoSettings.play,
    itemClick: 'nothing',
    videoSound: options.videoSettings.sound,
    showVideoPlayButton: options.videoSettings.showPlayButton,
    videoLoop: options.videoSettings.loop,
    videoSpeed: options.videoSettings.playbackSpeed,
  };
  const cardsRowOptions =
    options.layoutCardsRowType ===
      PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.ITEMS_PER_ROW &&
    options.layoutPostsPerRow
      ? {
          gridStyle: 1,
          numberOfImagesPerRow: options.layoutPostsPerRow,
        }
      : {};
  const cubeType =
    `${options.layoutImageCropType}` === PG_LAYOUT_IMAGE_CROP_TYPE_OPTIONS.MAX
      ? CUBE_TYPE.MAX
      : CUBE_TYPE.FILL;
  const cubeRatio = getLayoutImageRatio(
    layout,
    options,
    options.layoutImageCropType,
  );

  const textBoxOptions =
    options.layoutImageResizingMode ===
    PG_LAYOUT_IMAGE_RESIZING_MODE_OPTIONS.FIXED
      ? {
          textBoxWidth: options.layoutPostSize - options.layoutImageWidth,
          calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.MANUAL,
        }
      : {
          textBoxWidthPercent: Math.abs(options.layoutImageProportions - 100),
          calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.PERCENT,
        };

  switch (layout) {
    case LAYOUT_PG_TEXT_ON_IMAGE_SMALL:
    case LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM:
    case LAYOUT_PG_TEXT_ON_IMAGE_LARGE:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        cubeImages: true,
        cubeType: CUBE_TYPE.FILL,
        cubeRatio,
        titlePlacement: TEXT_PLACEMENT.SHOW_ON_HOVER,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NO_CHANGE,
      };
    case LAYOUT_PG_SIDE_BY_SIDE:
    case LAYOUT_PG_SIDE_BY_SIDE_RIGHT:
      return {
        ...commonGalleryOptions,
        ...textBoxOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        cubeType,
        cubeRatio,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: options.textBoxAlignment,
        gridStyle: 1,
        numberOfImagesPerRow: 1,
      };
    case LAYOUT_PG_CARD_MEDIUM:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        galleryLayout: 1,
        isVertical: true,
        cubeImages: true,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
    case LAYOUT_PG_ONE_COLUMN:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        isVertical: true,
        cubeImages: true,
        cubeType: CUBE_TYPE.MIN,
        cubeRatio,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
    case LAYOUT_SLIDER:
      return {
        ...commonGalleryOptions,
        galleryLayout: 5,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        showArrows: options.showArrows,
        slideshowInfoSize: options.layoutContentHeight,
        arrowsSize: options.arrowsSize,
        isAutoSlideshow: options.autoSlide,
        autoSlideshowInterval: options.pauseTime,
        arrowsPosition: options.arrowsPosition,
        arrowsColor: options.arrowsColor.value,
        slideshowLoop: options.loop,
        layoutParams: {
          navigationArrows: {
            verticalAlignment: 'IMAGE_CENTER',
          },
        },
      };
    case LAYOUT_LIST:
    case LAYOUT_LIST_MEDIUM:
    case LAYOUT_LIST_LARGE:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        ...textBoxOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: `SHOW_BELOW,${options.textBoxAlignment}`,
        textBoxHeight: options.layoutContentHeight,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
      };
    case LAYOUT_PG_GRID:
    case LAYOUT_PG_GRID_INTERMEDIATE:
    case LAYOUT_PG_GRID_LARGE:
    default:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        galleryLayout: 2,
        placeGroupsLtr: true,
        isVertical: true,
        cubeImages: true,
        cubeType,
        cubeRatio,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
  }
};

export const toLayoutFixerStyles = (options) => ({
  collageAmount: 0,
  collageDensity: 0,
  cubeImages: options.cubeImages,
  cubeRatio: options.cubeRatio,
  cubeType: options.cubeType,
  externalInfoHeight: options.textBoxHeight,
  groupSize: 1,
  groupTypes: '1',
  imageMargin: options.imageMargin,
  isVertical: true,
  targetItemSize: options.gallerySizePx,
});

// link to pro-gallery playground: https://pro-gallery.surge.sh
